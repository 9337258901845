import styled from 'styled-components/macro';
import {transparentize} from "polished"
import {
    Box,
    Heading,
    Button,
    Paragraph
} from '~styled';
const Feature = styled(Box)
`
padding-top: 20px;
padding-bottom: 20px;
@media (min-width: 768px){
    padding-top: 60px;
    padding-bottom: 60px;
}
@media (min-width: 992px){
    padding-top: 100px;
    padding-bottom: 100px;
}
`
Feature.Image = styled(Box)`
  position: relative;
  margin-bottom: 40px;
  z-index: 1;

  @media (min-width: 1200px) {
    margin-bottom: 0;
    margin-right: 55px;
  }
  img {
    border-radius: 12px;
  }
`
Feature.Block = styled(Box)`
  padding-top: 60px;
  padding-bottom: 60px;

  @media (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media (min-width: 992px) {
    padding-top: 132px;
    padding-bottom: 135px;
  }
`
Feature.Title = styled(Heading)
`
font-size: 32px;
    font-weight: 500;
    text-align: center;
    letter-spacing: normal;
    line-height: 1.2;
    color: #262729;
    margin-top: 60px;
    @media (min-width:768px) {
        font-size: 35px;
    }

    @media (min-width:992px) {
        font-size: 45px;
    }
`
Feature.Heading = styled(Heading)`
  font-size: 35px;
  font-weight: 600;
  letter-spacing: -2px;
  line-height: 1.20689;
  margin-bottom: 32px;

  @media (min-width: 480px) {
    font-size: 38px;
  }

  @media (min-width: 768px) {
    font-size: 48px;
  }

  @media (min-width: 992px) {
    font-size: 43px;
  }
`
Feature.Button = styled(Button)`
    display: block;
    align-items: center;
    transition:.4s;
    margin-bottom: 40px;
    margin-top: 25px;
    border-box: 10px;
    border-color: grey;
    &:hover{
    transform: translateY(-5px);
    border-color: grey;

  }
`
Feature.BackButton = styled(Button)`
    display: block;
    align-items: center;
    transition:.4s;
    margin-bottom: 40px;
    &:hover{
    transform: translateY(-5px);

  }
`

Feature.Text = styled(Paragraph)
`
font-size: 16px;
font-weight: 400;
letter-spacing: normal;
line-height: 1.777777;
margin-top: 30px;
@media (min-width: 992px){
    font-size: 18px;
}
`
Feature.Para = styled(Paragraph)`
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.66666;
  margin-bottom: 12px;
`
Feature.SubPara = styled(Paragraph)`
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.66666;
  margin-bottom: 12px;
`
Feature.MiniHeading = styled(Paragraph)`
font-size: 16px;
font-weight: 500;
color: #000;
font-style: normal;
letter-spacing: normal;
line-height: 1.66666;
margin-bottom: 12px;
`

Feature.Box = styled(Box)
``

export default Feature;