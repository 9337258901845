import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { SuperTag } from '~components';
import { Link } from '~components'
import Feature from './style'
import img from "~assets/image/customer-stories/feature-story.png";
import img2 from "~assets/image/customer-stories/feature-story-2.png";
import img3 from "~assets/image/customer-stories/live-yoga-teachers.png"
import img4 from "~assets/image/customer-stories/trusted-teams.jpg"
import img5 from "~assets/image/customer-stories/document-management.jpg"
import img6 from "~assets/image/customer-stories/marketing-agency.jpg"
import VideoWidget from './components/video-widget/VWidget';
import VideoJSFour from './components/Videojs/VideoJSFour';
import VideoJSFive from './components/Videojs/VideoJSFive';
import VideoJSOne from './components/Videojs/VideoJSOne';
import VideoJSTwo from './components/Videojs/VideoJSTwo';
import VideoJSThree from './components/Videojs/VideoJSThree';
import Slider, {Slide} from "./components/carousel/slider";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

export default function FeatureStory({backgroundColor}){
  
return(  
      
<Feature>
  <Container>
  <Row className="justify-content-center mb-6">
      <Col className="col-xl-8 col-lg-9 col-md-11 text-center">
        <Feature.Box>
          <Feature.Title as="h1">Customer Stories</Feature.Title>
          <Feature.Text>Global enterprises. Small business. Nonprofits. They all use We-connect to streamline lead generation with intelligent LinkedIn outreach.</Feature.Text>
        </Feature.Box>
      </Col>
    </Row>

  <Slider
      settings={{
        navigation: true
      }}
    >
      
        <Slide>
          <Feature.Block>
          <Row className=" justify-content-lg-start">
          <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <Feature.Image>
          <VideoJSFour videoId="vyaos7fab1" wrapper="wistia-player-container-1"/>
            </Feature.Image> 
            </Col>
          <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <Feature.Box>
              <Feature.MiniHeading> <SuperTag value="User Testing"/> </Feature.MiniHeading>
                <Feature.Heading as="h2"> <SuperTag value="RSC Sales Booked 5x Meetings For a Client With We-Connect"/> </Feature.Heading>
                <Feature.Para > <SuperTag value="RSC Sales is a consulting firm covering all angles of the sales process. With a focus on customer retention and acquisition."/> </Feature.Para>
                <Link to="/rsc-sales"><Feature.Button textColor="#fff" hoverTextColor="#000" backgroundColor="#FF4949" hoverBackgroundColor="#FF4949" sizeX="110px" sizeY="56px">Watch Story</Feature.Button></Link>
                </Feature.Box>
            </Col>
          </Row>
          </Feature.Block>
          </Slide>
          <Slide>
          <Feature.Block>
          <Row className=" justify-content-lg-start">
          <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <Feature.Image>
            <VideoJSFive videoId="p8tdeuqai7" wrapper="wistia-player-container-2"/> 
            </Feature.Image>
            </Col>
          <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <Feature.Box>
              <Feature.MiniHeading> <SuperTag value="Event promotion"/> </Feature.MiniHeading>
                <Feature.Heading as="h2"> <SuperTag value="How Talent Collective Boosted Its ROI With We-Connect’s Event Promotion"/> </Feature.Heading>
                
                <Link to="/talent-collective"><Feature.Button textColor="#fff" hoverTextColor="#000" backgroundColor="#FF4949" hoverBackgroundColor="#FF4949" sizeX="110px" sizeY="56px">Watch Story</Feature.Button></Link>
                </Feature.Box>
            </Col>
          </Row>
          </Feature.Block>
        </Slide>
        <Slide>
          <Feature.Block>
          <Row className=" justify-content-lg-start">
          <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <Feature.Image>
            <VideoJSOne videoId="yms3aeomju" wrapper="wistia-player-container-3"/> 
            </Feature.Image> 
            </Col>
          <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <Feature.Box>
              <Feature.MiniHeading> <SuperTag value="Scale Networking"/> </Feature.MiniHeading>
                <Feature.Heading as="h2"> <SuperTag value="Guide Social Scales Conversations Using We-Connect"/> </Feature.Heading>
                
                <Link to="/guide-social"><Feature.Button textColor="#fff" hoverTextColor="#000" backgroundColor="#FF4949" hoverBackgroundColor="#FF4949" sizeX="110px" sizeY="56px">Watch Story</Feature.Button></Link>
                </Feature.Box>
            </Col>
          </Row>
          </Feature.Block>
        </Slide>
        <Slide>
          <Feature.Block>
          <Row className=" justify-content-lg-start">
          <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <Feature.Image>
            <VideoJSTwo videoId="0aevc6qgrn" wrapper="wistia-player-container-4"/> 
            </Feature.Image> 
            </Col>
          <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <Feature.Box>
              <Feature.MiniHeading> <SuperTag value="Personalized Onboarding"/> </Feature.MiniHeading>
                <Feature.Heading as="h2"> <SuperTag value="Houseware Books 10X More Outbound Calls Using We-Connect"/> </Feature.Heading>
                
                <Link to="/houseware"><Feature.Button textColor="#fff" hoverTextColor="#000" backgroundColor="#FF4949" hoverBackgroundColor="#FF4949" sizeX="110px" sizeY="56px">Watch Story</Feature.Button></Link>
                </Feature.Box>
            </Col>
          </Row>
          </Feature.Block>
        </Slide>
        <Slide>
          <Feature.Block>
          <Row className=" justify-content-lg-start">
          <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <Feature.Image>
            <VideoJSThree videoId="ob521vqot3" wrapper="wistia-player-container-5"/> 
            </Feature.Image> 
            </Col>
          <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <Feature.Box>
              <Feature.MiniHeading> <SuperTag value="Simplify Lead-Gen"/> </Feature.MiniHeading>
                <Feature.Heading as="h2"> <SuperTag value="How Process to Profits Saves Its Client’s Time With We-Connect"/> </Feature.Heading>
                
                <Link to="/process-to-profits"><Feature.Button textColor="#fff" hoverTextColor="#000" backgroundColor="#FF4949" hoverBackgroundColor="#FF4949" sizeX="110px" sizeY="56px">Watch Story</Feature.Button></Link>
                </Feature.Box>
            </Col>
          </Row>
          </Feature.Block>
        </Slide>

      
    </Slider>

    <Slider
      settings={{
        navigation: true
      }}
    >
      
        <Slide>
        <Feature.Block>
        <Row className=" justify-content-lg-start">
        <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
            <Feature.Box>
            <Feature.MiniHeading > <SuperTag value="Secure pipeline"/> </Feature.MiniHeading>
              <Feature.Heading as="h2"> <SuperTag value="How Revisely Built a Healthy Sales Pipeline Using We-Connect"/> </Feature.Heading>
              <Feature.Para > <SuperTag value="“One thing I like about We-Connect is the support team – they’re always available to help me with any questions I have.”"/> </Feature.Para>
              <Feature.SubPara> <b>Sartou Djibril</b>, Head of Commercial Sales at Revisely</Feature.SubPara>
              <Link to="/revisely"><Feature.Button textColor="#fff" hoverTextColor="#000" backgroundColor="#FF4949" hoverBackgroundColor="#FF4949" sizeX="110px" sizeY="56px" >Read Story</Feature.Button></Link>
            </Feature.Box>
          </Col>
        <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
            <Feature.Image>
                <img  src={img} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />
            </Feature.Image>
            </Col>
          </Row>
        </Feature.Block>
        </Slide>
        <Slide>
        <Feature.Block>
          <Row className=" justify-content-lg-start">
          <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <Feature.Box>
              <Feature.MiniHeading> <SuperTag value="Expand network"/> </Feature.MiniHeading>
                <Feature.Heading as="h2"> <SuperTag value="How Burns Capital Boosted Its Connection Rate to 38% With We-Connect"/> </Feature.Heading>
                <Link to="/burns-capital"><Feature.Button textColor="#fff" hoverTextColor="#000" backgroundColor="#FF4949" hoverBackgroundColor="#FF4949" sizeX="110px" sizeY="56px">Read Story</Feature.Button></Link>
                </Feature.Box>
            </Col>
          <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <Feature.Image>
                  <img  src={img2} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />
              </Feature.Image>
            </Col>
          </Row>
        </Feature.Block>
        </Slide>
        <Slide>
        <Feature.Block>
          <Row className=" justify-content-lg-start">
          <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
                <Feature.Image>
                  <img  src={img3} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />
              </Feature.Image>
            </Col>
          <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <Feature.Box>
              <Feature.MiniHeading> <SuperTag value="Client acquisition"/> </Feature.MiniHeading>
                <Feature.Heading as="h2"> <SuperTag value="Automated Messaging Campaigns Boosted Live Yoga Teacher's Client Acquisition"/> </Feature.Heading>
                <Link to="/live-yoga-teachers"><Feature.Button textColor="#fff" hoverTextColor="#000" backgroundColor="#FF4949" hoverBackgroundColor="#FF4949" sizeX="110px" sizeY="56px">Read Story</Feature.Button></Link>
                </Feature.Box>
          </Col>
          </Row>
        </Feature.Block>
        </Slide>
        <Slide>
        <Feature.Block>
          <Row className=" justify-content-lg-start">
          <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
                <Feature.Image>
                  <img  src={img4} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />
              </Feature.Image>
            </Col>
          <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <Feature.Box>
              <Feature.MiniHeading> <SuperTag value="Boost Lead Engagement"/> </Feature.MiniHeading>
                <Feature.Heading as="h2"> <SuperTag value="How Trusted Teams Reengaged Previous Customers with a New Offer"/> </Feature.Heading>
                <Link to="/trusted-teams"><Feature.Button textColor="#fff" hoverTextColor="#000" backgroundColor="#FF4949" hoverBackgroundColor="#FF4949" sizeX="110px" sizeY="56px">Read Story</Feature.Button></Link>
                </Feature.Box>
          </Col>
          </Row>
        </Feature.Block>
        </Slide>
        <Slide>
        <Feature.Block>
          <Row className=" justify-content-lg-start">
          <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
                <Feature.Image>
                  <img  src={img5} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />
              </Feature.Image>
            </Col>
          <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <Feature.Box>
              <Feature.MiniHeading> <SuperTag value="Lead Nurture"/> </Feature.MiniHeading>
                <Feature.Heading as="h2"> <SuperTag value="Transforming Document Management: ACI's Journey with We-Connect"/> </Feature.Heading>
                <Link to="/ash-conversions-international"><Feature.Button textColor="#fff" hoverTextColor="#000" backgroundColor="#FF4949" hoverBackgroundColor="#FF4949" sizeX="110px" sizeY="56px">Read Story</Feature.Button></Link>
                </Feature.Box>
          </Col>
          </Row>
        </Feature.Block>
        </Slide>
        <Slide>
        <Feature.Block>
          <Row className=" justify-content-lg-start">
          <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
                <Feature.Image>
                  <img  src={img6} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />
              </Feature.Image>
            </Col>
          <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <Feature.Box>
              <Feature.MiniHeading> <SuperTag value="Time Savings"/> </Feature.MiniHeading>
                <Feature.Heading as="h2"> <SuperTag value="How Cox Marketing Agency Leverages We-Connect To Help Business Owners Overcome Prospecting Challenges"/> </Feature.Heading>
                <Link to="/cox-marketing-agency"><Feature.Button textColor="#fff" hoverTextColor="#000" backgroundColor="#FF4949" hoverBackgroundColor="#FF4949" sizeX="110px" sizeY="56px">Read Story</Feature.Button></Link>
                </Feature.Box>
          </Col>
          </Row>
        </Feature.Block>
        </Slide>
    </Slider>

  </Container>
</Feature>

)
}